<script>
import menus from "@utils/menu-items.json";
import { mapGetters } from "vuex"

export default {
    name: "SidebarComponent",
    data() {
        return {
            isCollapsed: false,
            dropdownOpen: false,
            activeTab: "admin.dashboard",
            menus,
        };
    },
    created() {
        this.setActiveTab();
    },
    mounted() {
        if (window.innerWidth <= 991) {
            this.collapsed = !this.collapsed;
        }
    },
    methods: {
        handleSelect(key) {
            this.activeTab = key;
        },
        setActiveTab() {
            const routeName = this.$route.name;
            this.activeTab = routeName;

            if (routeName === "noc.detail") {
                this.activeTab = "manage.noc.list";
            }
        },
        showItem(item) {
            if(this.isDealer) {
                if(item.showTo === "dealer") return true
                
                return false
            } else if(this.isSuperAdmin) {
                if(item.showTo === "super_admin") return true
                
                return false
            }
            return false
        },
    },
    computed: {
        ...mapGetters("account", ["isDealer", "isSuperAdmin"])
    }
};
</script>

<template lang="pug">
.sidebar.mt-14.fixed.z-30.inset-y-0.left-0.w-60.transform.bg-gray-900.overflow-y-auto
    .custom-sidebar
        el-menu.items-center.duration-200.bg-gray-900(
            :default-active="activeTab",
            :collapse="isCollapsed",
            :unique-opened="true",
            :collapse-transition="false",
            @select="handleSelect"
        )
            template(v-for="menu in menus")
                el-submenu(
                    v-if="menu.submenus && menu.submenus.length",
                    :index="menu.submenus[0].route"
                )
                    template(slot="title")
                        i(:class="menu.icon")
                        span(slot="title") {{ menu.title }}
                    el-menu-item-group
                        el-menu-item(
                            v-for="submenu in menu.submenus",
                            :key="submenu.title",
                            :index="submenu.route",
                            @click="$utility.goToPage(submenu.route)",
                            :disabled="submenu.disabled"
                        ) {{ submenu.title }}
                el-menu-item.border(
                    :index="menu.route",
                    v-if="showItem(menu)",
                    @click="$utility.goToPage(menu.route)",
                    :class="{ 'is-active': menu.route === activeTab }"
                )
                    i(:class="menu.icon")
                    span(slot="title") {{ menu.title }}
</template>

