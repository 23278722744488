<script >
import Sidebar from "./components/sidebar";
import HeaderComponent from "./components/header";

export default {
    name: "AdminLayout",
    components: {
        HeaderComponent,
        Sidebar,
    },
    data() {
        return {
            isCollapsed: false,
        }
    }
};
</script>

<template lang="pug">
.flex.h-screen.bg-gray-200.font-roboto
    sidebar
    .flex-1.flex.flex-col.overflow-hidden
        header-component
        main.ml-60.flex-1.overflow-x-hidden.overflow-y-auto.bg-gray-200
            .container.mx-auto.px-3.py-2
                slot
</template>

