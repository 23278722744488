<script>
import FormFieldInput from "@components/form-field-input";

export default {
    name: "DataTable",
    components: {
        FormFieldInput
    },
    props: {
        count: {
            type: Number,
            default: 0,
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
        showAdd: {
            type: Boolean,
            default: false,
        },
        addTitle: {
            type: String,
            default: "Add",
        },
        disableAdd: {
            type: Boolean,
            default: false
        }
    },
    emits: ["fetch", "search", "addClick"],
    data() {
        return {
            pagination: {
                "current-page": 1,
                "page-size": 10,
            },
            search: ""
        };
    },
    created() {
        this.pagination = {
            "current-page": 1,
            "page-size": 10,
        };
    },
    methods: {
        handleCurrentChange(page, index) {
            this.pagination["current-page"] = page;

            this.$emit("fetch", {
                current: this.pagination["current-page"],
                limit: this.pagination["page-size"],
            });
        },
    },
    watch: {
        pagination(val) {
            this.$emit("fetch", {
                current: this.pagination["current-page"],
                limit: this.pagination["page-size"],
            });
        },
    },
};
</script>

<template lang="pug">
.container.mx-auto.divide-y
    .flex.justify-between.items-center(v-if="showSearch || showAdd")
        div(v-if="showSearch")
            form-field-input(
                placeholder="Type here to search",
                inputType="search",
                :showClear="true",
                v-model="search",
                attribute="search",
                @input="$emit('search', $event)"
            )
        div(v-if="showAdd")
            button.bg-blue-500.text-white.font-bold.py-2.px-4.rounded-full(
                class="hover:bg-blue-700"
                @click="$emit('addClick')"
                :disabled="disableAdd"
            ) {{ addTitle }}
    .my-1
        slot
    .block
        el-pagination(
            @current-change="handleCurrentChange",
            v-bind.sync="pagination",
            layout="total, prev, pager, next",
            :total="count"
        )
</template>